import React, { useContext, useState } from "react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "next/link";
import styles from "@styles/styles";
import Image from "next/image";
import theme from "@styles/theme";

const logo = (
  <Link href="/">
    <a style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Image width={157} height={40} src="/images/logo.png" />
    </a>
  </Link>
);

export default function Component(props: {
  permanentDrawer: boolean;
  handleDrawerToggle: any;
  childrenOnSmallScreen?: React.ReactChild | React.ReactChild[];
  childrenOnLargeScreen?: React.ReactChild | React.ReactChild[];
}) {
  return (
    <AppBar
      position="fixed"
      style={{
        background: "#fff",
        boxShadow: "0px 0px 9px 5px rgba(0,0,0,0.17)",
        outlineStyle: "solid",
        outlineColor: theme.palette.grey[300],
        outlineWidth: "1px",
        height: styles.navBarHeight,
        width: props.permanentDrawer
          ? `calc(100% - ${styles.drawerWidth}px)`
          : "100%",
        marginLeft: props.permanentDrawer ? styles.drawerWidth : 0,
      }}
    >
      {!props.permanentDrawer && (
        <Toolbar style={{ height: "100%" }}>
          <div
            style={{ display: "flex", alignItems: "center", minWidth: "180px" }}
          >
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              style={{
                color: theme.palette.primary.dark,
                marginRight: theme.spacing(2),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                width: "100%",
                marginRight: theme.spacing(2),
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {logo}
            </div>
          </div>
          {props.childrenOnSmallScreen}
        </Toolbar>
      )}

      {props.permanentDrawer && (
        <Toolbar style={{ height: "100%" }}>
          {props.childrenOnLargeScreen}
        </Toolbar>
      )}
    </AppBar>
  );
}
