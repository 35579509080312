import Link from "next/link";

export type LinkToPageProps = {
  url: string;
  openNewTab?: boolean;
  children: React.ReactChild | React.ReactChild[];
};

export default function LinkToPage({ openNewTab = false, ...props }: LinkToPageProps) {
  return (
    <Link href={props.url} passHref>
      <a
        target={openNewTab ? "_blank" : ""}
        rel="noreferrer"
        style={{ textDecoration: "inherit", color: "inherit", width: "100%", height: "100%" }}
      >
        {props.children}
      </a>
    </Link>
  );
}
