import React, { useContext, useState } from "react";
import {
  Drawer,
  DrawerProps,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Link from "next/link";
import styles from "@styles/styles";
import Image from "next/image";
import DividerTitle from "./DividerTitle";
import LinkToPage from "@client/navigation/components/LinkToPage";
import theme from "@styles/theme";

const MenuEmoji = (props: { emoji: string }) => {
  return (
    <div
      style={{
        fontSize: 20,
        paddingRight: theme.spacing(3),
        textAlign: "center",
      }}
    >
      {props.emoji}
    </div>
  );
};

const logo = (
  <Link href="/">
    <a style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Image width={130} height={40} src="/images/logo.png" />
    </a>
  </Link>
);

export default function Component(props: {
  handleDrawerToggle: any;
  permanent: boolean;
  drawerOpen: boolean;
}) {
  // const classes = useStyles();

  const nonPermanentDrawerProps: DrawerProps = {
    variant: "temporary",
    anchor: theme.direction === "rtl" ? "right" : "left",
    open: props.drawerOpen,
    onClose: props.handleDrawerToggle,
    onClick: props.handleDrawerToggle,
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  const permanentDrawerProps: DrawerProps = {
    open: true,
    variant: "permanent",
  };

  const drawerProps = props.permanent
    ? permanentDrawerProps
    : nonPermanentDrawerProps;

  return (
    <Drawer
      {...drawerProps}
      // classes={{
      //   paper: classes.drawerPaper,
      // }}
    >
      <div
        role="presentation"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: styles.drawerWidth,
          flexShrink: 0,
        }}
      >
        <div>
          <div
            style={{
              height: styles.navBarHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: theme.spacing(2),
            }}
          >
            {logo}
            {!props.permanent && (
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
                edge="start"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>

          <List style={{ padding: 0 }}>
            <Divider />

            <DividerTitle title={"Introduction"} />
            <LinkToPage url="/">
              <ListItem button component="a" key={"Home"}>
                <MenuEmoji emoji="🏠" />
                <ListItemText primary={"Home"} />
              </ListItem>
            </LinkToPage>

            <DividerTitle title={"Belgian Routes 🇧🇪"} />
            <LinkToPage url="/routes/self-employed-basics">
              {" "}
              <ListItem button key={"Self-Employed Basics"}>
                <MenuEmoji emoji="👱" />
                <ListItemText primary={"Self-Employed Basics"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/routes/manco-optimisation">
              <ListItem button key={"Manco Optimisation"}>
                <MenuEmoji emoji="🏢" />
                <ListItemText primary={"Manco Optimisation"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/routes/manco-comparison">
              <ListItem button key={"Manco Comparison"}>
                <MenuEmoji emoji="⚖️" />
                <ListItemText primary={"Manco Comparison"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/routes/one-man-business-pension">
              <ListItem button key={"One-Man-Business pension"}>
                <MenuEmoji emoji="👵" />
                <ListItemText primary={"One-Man-Business Pension"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/routes/director-pension">
              <ListItem button key={"Director pension"}>
                <MenuEmoji emoji="👴" />
                <ListItemText primary={"Director Pension"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/routes/charging-station-cost">
              <ListItem button key={"Charging station cost"}>
                <MenuEmoji emoji="🔋" />
                <ListItemText primary={"Charging Station Cost"} />
              </ListItem>
            </LinkToPage>
            <DividerTitle title={"Contact"} />
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Request an API key"}>
                <MenuEmoji emoji="🔑" />
                <ListItemText primary={"Request an API key"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Pricing"}>
                <MenuEmoji emoji="🏷" />
                <ListItemText primary={"Pricing"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Get in touch"}>
                <MenuEmoji emoji="ℹ️" />
                <ListItemText primary={"Get in touch"} />
              </ListItem>
            </LinkToPage>
          </List>
        </div>

        <List style={{ padding: 0 }}>
          <Divider style={{ marginBottom: theme.spacing(1) }} />
          <ListItem button key={"Cookies"}>
            <MenuEmoji emoji="🍪" />
            <ListItemText primary={"Cookies"} />
          </ListItem>
          <LinkToPage url="https://www.aeco.cloud" openNewTab={true}>
            <ListItem button key={"Powered by Aeco"}>
              <MenuEmoji emoji="⚡️" />
              <ListItemText primary={"Powered by Aeco"} />
            </ListItem>
          </LinkToPage>
        </List>
      </div>
    </Drawer>
  );
}
