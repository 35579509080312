const navBarHeight = "65px";

const styles = {
  navBarHeight: navBarHeight,
  minPageHeight: `calc(100vh - ${navBarHeight})`,
  drawerWidth: 280,
  formPanelWidth: "30vw",
  resultPanelWidth: "70vw",
  simulateButtonHeight: "80px"
};

export default styles;
