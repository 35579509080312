import Navigation from "./Navigation";
import Link from "next/link";
import { Button } from "@mui/material";

const createDocumentButtonRight = (
  <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
    <Link href="/get-in-touch" passHref>
      <Button color="primary" variant="contained">
        Get in touch
      </Button>
    </Link>
  </div>
);

export default function CreatorNavigation(props: {
  children: React.ReactChild | React.ReactChild[];
}) {
  return (
    <Navigation
      appBarChildrenWithPermanentDrawer={createDocumentButtonRight}
      appBarChildrenWithoutPermanentDrawer={createDocumentButtonRight}
    >
      {props.children}
    </Navigation>
  );
}
