import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  makeStyles,
} from "@mui/material";
import theme from "@styles/theme";

// const useStyles = makeStyles({
//   featureCard: {
//     "&:hover": {
//       boxShadow: theme.shadows[5],
//     },
//   },
// });

const FeatureCard = (props: {
  icon?: React.ReactChild | React.ReactChild[];
  title?: string;
  description?: string;
  emailButton?: { title: string; email: string };
}) => {
  // const classes = useStyles();
  return (
    <Paper
      // className={classes.featureCard}
      sx={{
        ":hover": { boxShadow: theme.shadows[5] },
        minHeight: "300px",
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box pb={3}>
        <Typography variant="h6">{props.title}</Typography>
      </Box>
      <Box pb={3} fontSize={40}>
        {props.icon}
      </Box>
      <Box pb={props.emailButton ? 3 : 0}>
        <Typography>{props.description}</Typography>
      </Box>

      {props.emailButton && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ maxWidth: "200px" }}
          href={`mailto: ${props.emailButton.email}`}
        >
          {props.emailButton.title}
        </Button>
      )}
    </Paper>
  );
};

export default FeatureCard;
