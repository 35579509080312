import { createTheme, responsiveFontSizes } from "@mui/material";

const primaryMainColor = "#3eaf7c";

const theme = createTheme({
  palette: {
    primary: {
      light: "#64bf96",
      main: "#3eaf7c",
      dark: "#2b7a56",
      contrastText: "#fff",
    },
    secondary: {
      light: "#42525f",
      main: "#132738",
      dark: "#0d1b27",
      contrastText: "#fff",
    },
    text: {
      primary: "#514947",
    },
  },
  typography: {
    h1: {
      fontSize: "3.33rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    h2: {
      fontSize: "2.33rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    h3: {
      fontSize: "1.8rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    h5: {
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    h6: {
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: primaryMainColor,
    },
    subtitle1: { fontSize: 14, lineHeight: 1.5 },
    body1: { fontSize: 14 },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {},
});

// theme.overrides = {
//   MuiCssBaseline: {
//     "@global": {
//       html: {
//         height: "100%",
//         width: "100%",
//         margin: "0",
//         padding: "0",
//       },
//       body: {
//         height: "100%",
//         width: "100%",
//         margin: "0",
//         padding: "0",
//       },
//     },
//   },
// };

export default responsiveFontSizes(theme);
// export default theme;
